@import "common/ie6.1.1";

body {
  width: 100%;
}

.alert-browser {
  background-color: #a90112;
  border-width: 0;
  color: white;
  line-height: 1;
  margin-bottom: 15px;
  padding: 5px;
  text-align: center;
  a {
    color: white;
    font-weight: 600;
    text-decoration: underline;
  }
  p {
    margin: 5px 0;
  }
}

img,
svg {
  max-width: 300px;
}

.nav-contact,
.navbar-toggler,
.sr-only {
  display: none;
}
